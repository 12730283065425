html,
body {
  scroll-behavior: smooth;
  line-height: 1.5;
}

::-webkit-scrollbar {
  display: none;
}

.public-DraftEditor-content {
  overflow: auto !important;
  padding-top: 20px;
}

.editor_container_main {
  transition: padding 200ms ease-in-out 0s;
  padding: 48px 112px 160px;
  overflow-y: auto;
  max-height: 100vh;
}
.editor_container_main.header_extended {
  padding: 208px 112px 160px;
}

.SpeakerBlockWrapper_wrapper {
  line-height: 1.5;
}

.live_text_main_wrapper {
  font-weight: 400;
  line-height: 1.5;
  padding-top: 20px;
  /* letter-spacing: 0.01071em; */
  letter-spacing: 0.0069em;
  outline: none;
  user-select: text;
  /* white-space: pre-wrap; */
  overflow-wrap: break-word;

  display: flex;
  flex-direction: column;
}

.live_text_p_block {
  font-size: 22px;
  font-family: Roboto Mono;
  color: #707070;
  line-height: 1.5;
  /* vertical-align: text-bottom; */
  min-height: 33px;
  white-space: pre-wrap;
  margin: 0;
  margin-bottom: 0px; /*40px*/
}

.word {
  font-size: 1.9vw;
  font-family: Roboto Mono;
  color: #707070;
  background-color: #f7f7f7;
  border: 0.15vw solid rgba(0, 0, 0, 0);
  padding: 0.3vw 0.6vw 0.5vw;
  margin-top: 0.4vw;
  margin-bottom: 0.4vw;
}

[data-replacement='true'] {
  border-bottom: 2px dotted rgb(248, 5, 52);
}

.MuiGrid-root .MuiGrid-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.word:focus {
  outline: none;
}

.word_normal_selection::selection {
  background-color: #999999;
  color: #cccccc;
}

.word_is_selected {
  background-color: #999999 !important;
  color: #cccccc;
  border-color: transparent !important;
}

.word_is_selected::selection {
  background-color: transparent;
}

.word_is_edited {
  background-color: #0588f82e;
  color: #2b8dff !important;
}

.word_is_edited_border_left {
  border-left: 0.15vw solid #2b8dff;
}

.word_is_edited_border_right {
  border-right: 0.15vw solid #2b8dff;
}

.word_is_deleted {
  background-color: #f7053529;
  color: #ff6666 !important;
}

.word_is_active {
  color: #333333;
  font-weight: 700 !important;
}

.word_is_active::selection {
  background-color: transparent !important;
}

.word_is_before_active {
  color: #333333;
}

.word_is_before_active::selection {
  background-color: transparent !important;
}

.word_is_after_active {
  color: #cccccc;
}

#transcript_container {
  transform-origin: center top;
}

#word_start {
  line-height: initial;
  position: absolute;
  top: 0.3vw;
  left: 0.2vw;
  font-size: 0.5vw;
  font-family: Roboto Mono;
}

#word_end {
  line-height: initial;
  position: absolute;
  right: 0.2vw;
  bottom: 0.3vw;
  font-size: 0.5vw;
  font-family: Roboto Mono;
}

/*CONFIDENCE LVLS*/
.confidence_low {
  border-top: 0.15vw solid rgb(215, 128, 27);
  border-bottom: 0.15vw solid rgb(215, 128, 27);
}

.confidence_med {
  border-top: 0.15vw solid rgba(215, 128, 27, 0.5);
  border-bottom: 0.15vw solid rgba(215, 128, 27, 0.5);
}

.commands_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
}

.commands_wrapper .title {
  font-size: 16px;
  font-weight: 600;
}

.commands_wrapper .commands {
  margin: 2px;
  font-size: 14px;
}

.speaker-row {
  background-color: #f2f2f2;
}

.speaker-row:hover {
  background-color: #cccccc;
}

/* NEW EDITOR DRAFTJS CLASSES */

[data-block='true'] {
  margin-bottom: 0px; /*40px*/
  min-height: 33px;
}

.editor_wrapper_main {
  font-family: Roboto Mono;
  color: #707070;
  transform-origin: top;
  max-width: 1440px;
  margin: 0 auto;
  /* min-height: 35px; */

  margin-bottom: 17vh; /* has to be on for scroller to work in Firefox */
}

.editor_block_wrapper {
  margin-bottom: 0; /*40px*/
  min-height: 33px;
  border: none !important;
  outline: none !important;
  /* text-align: justify; */
}

.replacement_hover__bubble {
  user-select: none !important;
  position: absolute;
  right: 0;
  bottom: calc(100% - 5px);
  padding: 2px 5px;
  background-color: rgb(43, 141, 255);
  font-size: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.replacement_hover__bubble svg {
  margin-left: 5px;
  cursor: pointer;
}
.replacement_hover__bubble span {
  pointer-events: none !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none !important;
  white-space: pre;
}

.draft_word {
  font-family: Roboto Mono;
  color: #707070;
  line-height: 1.5;
  position: relative;
  /* vertical-align: text-bottom; */
  /* min-height: 35px; */
}
.draft_word.active {
  color: #333333 !important;
  font-weight: bold !important;
}
.draft_word.after {
  color: #cccccc !important;
  font-weight: normal !important;
}
.draft_word.before {
  color: #707070 !important;
  font-weight: normal !important;
}
.draft_word.conf_low {
  color: rgb(215, 128, 27) !important;
}
.draft_word.conf_med {
  color: rgba(215, 128, 27, 0.5) !important;
}
.draft_word.edited_word {
  color: #2b8dff !important;
}
.speaker_button_wrapper {
  user-select: none;
  width: fit-content;
  margin-bottom: 10px;
}
.speaker_button {
  cursor: pointer;
  border-bottom: 2px solid #2b8dff;
  color: #333333;
  font-family: 'Roboto Mono';
  /* font-size: 16px; */
}
.speaker_button_unclickable {
  cursor: default !important;
}

.new_word {
  font-family: Roboto Mono;
  line-height: 1.5;
  /* vertical-align: text-bottom; */
  /* background-color: #0588f82e; */
  /* color: #2b8dff !important; */
}

.confidence_borders {
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: transparent;
  pointer-events: none;
  display: flex;
  z-index: -1;
}

.show_scrollbar::-webkit-scrollbar {
  display: flex !important;
  width: 6px;
}
/* Track */
.show_scrollbar::-webkit-scrollbar-track {
  background: #e2e2e2;
}

/* Handle */
.show_scrollbar::-webkit-scrollbar-thumb {
  background: #2b8dff;
}

/* Handle on hover */
.show_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.last_pos_cursor {
  color: rgb(248, 5, 52);
  animation: heartbeat 800ms infinite ease-in;
}

.animating_dots {
  animation: heartbeat 800ms infinite ease-in;
}

.editor_style_control_button {
  color: rgb(204, 204, 204);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 48px;
  height: 48px;
}

.editor_style_control_button:hover {
  background-color: #2b8dff;
}

.mouse_off {
  pointer-events: none;
}

/* NEW EDITOR DRAFTJS CLASSES */

@media (max-width: 660px) {
  .editor_container_main {
    padding: 29px 29px 160px;
  }
  .editor_container_main.header_extended {
    padding: 148px 29px 160px;
  }
}

@keyframes heartbeat-border-blue {
  0% {
    border-color: #2b8eff30;
  }
  50% {
    border-color: #2b8eff;
  }
  100% {
    border-color: #2b8eff30;
  }
}

@keyframes heartbeat-border-red {
  0% {
    border-color: rgba(248, 5, 54, 0.303);
  }
  50% {
    border-color: rgb(248, 5, 52);
  }
  100% {
    border-color: rgb(248, 5, 52, 0.3);
  }
}

@keyframes heartbeat-border-grey {
  0% {
    border-color: #70707030;
  }
  50% {
    border-color: #707070;
  }
  100% {
    border-color: #70707030;
  }
}

@keyframes heartbeat {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes heartbeat {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
