#speaker_modal_header_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 35px;
}

#speaker_modal_header_title {
    font-family: Roboto;
    font-style: italic;
    font-size: 26px;
    color: #333333;
}

#speaker_modal_add_new_user_container {
    border-radius: 100px;
    display: flex;
    place-content: center;
    background-color: #2B8DFC;
    width: 24px;
    height: 24px;
}

#speaker_modal_search_input {
    margin-bottom: 17px;
    border-bottom: 1px solid #333333;
    width: 100%;
}

#speaker_modal_header_subtitle {
    font-family: Roboto;
    font-style: italic;
    font-size: 12px;
    color: #333333;
}

#speaker_list_container {
    max-height: 150px;
    overflow-y: scroll;
}

#speaker_modal_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
}

#spekaer_modal_close_button {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    color: #707070;
}

#spekaer_modal_save_button {
    padding: 7px;
    background-color: #2B8DFF;
    color: #F2F2F2;
    font-family: Roboto;
    font-size: 14;
    font-weight: 500;
    margin-left: 12px;
}

.speaker_row_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.speaker_row_name {
    font-family: Roboto;
    font-size: 18px;
    color: #323232;
}

.speaker_row_name:hover {
    cursor: pointer;
    color: #000000;
}

.speaker_row_name_local_or_changed {
    color: #767676;
}

#speaker_roles_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 24px;
}

.speaker_role {
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 4px 8px;
    border: 1px solid #2b8dff;
    color: #2b8dff;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
}

.speaker_role_delete {
    margin-right: 8px;
}

.speaker_role_delete_container {
    border-radius: 100px;
    background-color: #2B8DFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}

.speaker_role_delete_container.speaker_role_delete_container_default {
    border-radius: 100px;
    background-color: #F2F2F2;
}

.speaker_role.speaker_role_default {
    background-color: #2b8dff;
    color: #F2F2F2;
}

#speaker_modal_role_input_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
}

#speaker_modal_role_input {
    flex: 1;
}

.add_user_input {
    width: 100%;
    font-family: Roboto;
    color: #333333;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #ACACAC;
    outline: none;
    transition: border-color 0.3s ease;
}

.add_user_input:not(:placeholder-shown) {
    border-bottom: 1px solid #2b8dff;
}

/* Optional: Underline color on focus */
.add_user_input:focus {
    border-bottom: 1px solid #2b8dff;
}

#switch_all_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}