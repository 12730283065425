#speakers_vector_title {
    color: #333;
    font-family: Roboto;
    font-size: 26px;
    font-style: italic;
}

#end_button {
    background: #2b8dff;
    color: #ffffff;

    padding: 0.5em 1em;
    border-radius: 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 0.75rem;
    margin-left: 12px;
}

#end_button:disabled {
    background: #878787;
}

#close_button {
    color: #707070;
    padding: 0.5em 1em;
    border-radius: 0;
    font-weight: 700;
    cursor: pointer;
    font-size: 0.75rem;
}

#close_button:disabled {
    color: #b7b7b7;
}

.column-header-speaker {
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
}

#speaker-table-head {
    box-shadow: 0px 2px 0px #303030;
    position: sticky;
    top: 0;
    background-color: rgb(242, 242, 242);
    z-index: 1;
    /* Keeps header above scrolling content */
}

#table-container-speaker {
    max-height: 300px;
    overflow-y: auto;
}

#speaker-vector-table {
    width: 100%;
    border-collapse: collapse;
}

@keyframes gradientAnimation {
    0% {
        border-color: #4BB543;
    }

    100% {
        border-color: #22541e;
    }
}

.loader-speaker {
    border: 2px solid #4BB543;
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-left: 3px;
    border-radius: 2px;
    animation: gradientAnimation 0.5s infinite alternate ease-in-out;
}